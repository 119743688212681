<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center bg-clay overflow-auto">
        
        <div 
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-clay-light text-white p-8 flex flex-col rounded-t-lg md:rounded-none">
                <h2 class="text-2xl font-normal mb-2 leading-tight">{{$t('auth.signIn')}}</h2>
                <p>{{$t('auth.welcomeBackUser')}}</p>
            </div>
            <div 
                class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none"
                v-if="ready">
                
                <form 
                    class="w-full mb-1" 
                    @submit.prevent="signIn()">
                    
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        <!-- <h2 class="text-white">Error</h2> -->
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        :label="$t('auth.email')"
                        name="email"
                        :placeholder="$t('auth.email')"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                        :autofocus="true"
                    />
                    
                    <InputSingleline 
                        v-model="password"
                        :label="$t('auth.password')"
                        name="password"
                        :placeholder="$t('auth.password')"
                        class="w-full "
                        type="password"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        {{$t('auth.signIn')}}
                    </button>
                </form>
                
                <div class="text-center flex flex-col">
                    <router-link 
                        :to="{ name: 'resetpassword'}"
                        class="mb-2">
                        
                        {{$t('auth.forgotYourPassword')}}
                    </router-link>
                    
                    <router-link :to="{ name: 'signup'}">
                        {{$t('auth.dontHaveAUserYet')}}
                    </router-link>
                </div>
            </div>
        </div>
        
        <router-link :to="{ name: 'www'}">
            {{$t('auth.backToWebsite')}}
        </router-link>
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
        data() {
            return {
                ready: false,
                email: null,
                password: null,
                error: null,
                loading: false,
            }
        },
        
        async created() {
            const signedInStatus = await this.checkUser();
            
            if (signedInStatus) {
                this.$router.push({name: 'accounts'});
            }
            else {
                this.ready = true;
            }
        },
    }
</script>
